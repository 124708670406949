//import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';
//import Popper from 'popper.js';
//import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./assets/vendor/bootstrap/css/bootstrap.min.css"
import "./assets/vendor/icofont/icofont.min.css"
import "./assets/vendor/boxicons/css/boxicons.min.css"
import "./assets/vendor/animate.css/animate.min.css"
import "./assets/vendor/owl.carousel/assets/owl.carousel.min.css"
import "./assets/vendor/venobox/venobox.css" 

import "./assets/css/style.css"
///import './assets/css/style.css'
//import './assets/vendor/icofont/icofont.min.css'
import AuthState from "./context/auth/authState";
import PedidosState from "./context/pedidos/PedidosState";
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
 // <React.StrictMode></React.StrictMode>
 
   <AuthState>
     <PedidosState>
     <App />
   </PedidosState>
   </AuthState>
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
