  
  export default (state, action) => {
    switch (action.type) {
        case "CREATE_EGRESOS":
          return {
            ...state,
            crearItemCode:action.payload
          };
          case "GET_PARADAS":
          return {
            ...state,
            Paradas:action.payload,
          };
          case "GET_TOTALDIASEGRESOS":
          return {
            ...state,
           totaldia:action.payload===null?0:action.payload,
          };
      default:
        return state;
    }
  };
  