import React,{useReducer} from 'react'
import PedidosContext from "./PedidosContext";
import PedidosReducer from "./PedidosReducer";
import Axios from '../../config/Axios'
const ItemsState = props => {
 
  const initialState = {
    Pedidos:[],
    };

  const [state, dispatch] = useReducer(PedidosReducer, initialState);


  const getPedidosFn = async (fecha) => {
    try{
        const respuesta = await Axios.get(`/getPedidos`)
        if(respuesta.data.code === "success"){
          console.log(respuesta.data.result)
        }else{
          alert(`${respuesta.data.mensaje}`)   
        }
     }catch(e){
       
     }
   
  };



  


  return (
    <PedidosContext.Provider
      value={{
        pedidos:state.Pedidos,
        getPedidosFn
      }}
    >
      {props.children}
    </PedidosContext.Provider>
  );
};

export default ItemsState;
