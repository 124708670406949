import React, { useReducer, useState } from "react";
import Axios from "../../config/Axios";
import TokenAuth from "../../config/Token";
import { Redirect } from "react-router-dom";
import authContext from "./authContext";
import authReducer from "./authReducer";
import { AUTH_ERROR } from "../../types/index";

import Swal from 'sweetalert2'

const AuthState = (props) => {
  //var data = [{id: 1}, {id: 2}]

  // Encrypt
  //var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

  // Decrypt
  //var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
  //var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  //console.log(decryptedData); // [{id: 1}, {id: 2}]

  //STATE INICIAL
  const initialState = {
    token: null,
    autenticado: false,
    usuario: null,
    mensaje: null,
    nombres: null,
    cedula: null,
    rol: null,
  };

  // PROVEDOR
  const [state, setState] = useState(initialState);

  //INITIALIZACION FUNCTION SERIES
  ////////////////////////////////////////////////////////

  /*const registrarUsuariofn =async datos =>{
        try{
           const respuesta = await Axios.post('',datos)
        }catch(e){
          
        }
    }*/

  const authenticationfn = async (datos) => {
    try {
      const respuesta = await Axios.post("/login", datos);

      if (respuesta.data.sms === "ok") {
        localStorage.setItem("correo", respuesta.data.email);
        localStorage.setItem("token", respuesta.data.token);
        localStorage.setItem("usuario_id", respuesta.data.usuario_id);

        window.location = "/";
      } else {
        //alert(`${respuesta.data.sms} ${respuesta.data.mensaje}`);
        Swal.fire(
          'Atencion',
          respuesta.data.mensaje,
          'error'
        );
      }
    } catch (e) {}
  };

  const authenticationDataFn = async () => {
    const token = localStorage.getItem("token");
    //,{ headers: { "access-token": `${token}` } }
    TokenAuth(token);
    try {
      const respuesta = await Axios.get(
        "/dataUser/" + localStorage.getItem("correo")
      );

      if (
        respuesta.data.result[0].descripcion === "SUPER" ||
        respuesta.data.result[0].descripcion === "ADMINISTRADOR"
      ) {
        localStorage.setItem("rol", respuesta.data.result[0].descripcion);
      }
      // localStorage.setItem('alldata', JSON.stringify(respuesta.data.result[0]))
      localStorage.setItem(
        "nombres",
        respuesta.data.result[0].nombres +
          " " +
          respuesta.data.result[0].apellidos
      );
      localStorage.setItem("direccion", respuesta.data.result[0].direccion);
      localStorage.setItem("estado", respuesta.data.result[0].estado);
      localStorage.setItem("usuario_id", respuesta.data.result[0].usuario_id);
      setState({
        ...state,
        rol: localStorage.getItem("rol"),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const UsuarioRegistratfn = async (datos) => {
    try {
      const respuesta = await Axios.post("/createUser", datos);

      if (respuesta.data.sms === "ok") {
        localStorage.setItem("correo", respuesta.data.email);
        localStorage.setItem("usuario_id", respuesta.data.usuario_id);
        localStorage.setItem("estado", respuesta.data.estado);
        localStorage.setItem("token", respuesta.data.token);
        window.location = "/";
      } else {
        alert(`${respuesta.data.sms}`);
      }
    } catch (e) {}
  };

  const UsuarioEditfn = async (datos) => {
    try {
      const respuesta = await Axios.post("/editUser", datos);

      if (respuesta.data.sms === "ok") {
        localStorage.setItem("nombres", respuesta.data.nombres);
        localStorage.setItem("apellidos", respuesta.data.apellidos);
        localStorage.setItem("estado", respuesta.data.estado);
        localStorage.setItem("direccion", respuesta.data.direccion);
        window.location = "/";
      } else {
        alert(`${respuesta.data.sms}`);
      }
    } catch (e) {}
  };

  return (
    <authContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.mensaje,
        rol: state.rol,
        authenticationfn,
        authenticationDataFn,
        UsuarioRegistratfn,
        UsuarioEditfn,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};
export default AuthState;
