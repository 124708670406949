import React, { useState, useContext,useEffect } from 'react';
import "./App.css";
//import jQuery from 'jquery';
//import Popper from 'popper.js';
//npm install bootstrap popper jquery
import {
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import AuthContext from './context/auth/authContext'

import Layout from "./componentes/layouts/cliente/cliente-layout"
//import AdminLayout from "./componentes/layouts/admin/admin-layout"
function App() {

  const AuthenticationContext=useContext(AuthContext)
    const {authenticationDataFn} = AuthenticationContext


    useEffect(() => {
      
      authenticationDataFn()

    
    }, [])

  return (
    <div className="App">
      {
         //localStorage.getItem("login")? <AdminLayout></AdminLayout>  :  <Layout/>

      }
      <Router>
          <Route path="/" component={Layout} /> 
      </Router>
     
                              
    </div>
  );
}

export default App;
