import React, { Suspense, lazy, useEffect, useState, useContext } from "react";
import logo from "../../../assets/256x256.gif";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AuthContext from "../../../context/auth/authContext";
import { Redirect } from "react-router-dom";
import Log from "../../../assets/log.png";
import Log2 from "../../../assets/log2.png"
const Routes = lazy(() => import("../../../routes/routesCli"));
const Layout = () => {
  // console.log(JSON.parse(localStorage.getItem('alldata')))
  // console.log(localStorage.getItem('alldata')[0].clave)
  const AuthenticationContext = useContext(AuthContext);
  const { authenticationDataFn, rol } = AuthenticationContext;

  const [state, setState] = useState({
    rol: localStorage.getItem("rol"),
  });

  useEffect(() => {
    authenticationDataFn();
    setState({
      ...state,
      rol: rol,
    });
  }, [rol]);

  const salir = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location = "/";
  };

  return (
    <>
      <div
        id="topbar"
        className="d-none d-lg-flex align-items-center fixed-top"
      >
        <div className="container d-flex">
          <div className="contact-info mr-auto">
            <i className="icofont-envelope"></i>{" "}
            <a href="/#">atencion@ecuimport.com</a>
            <i className="icofont-phone"></i> +593 99 574 2725
          </div>
          <div className="social-links">
            {/* <a href="/#" className="twitter">
              <i className="icofont-twitter"></i>
            </a> */}
            <a
              href="https://www.facebook.com/ecuimport-100979977916059"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
            >
              <i className="icofont-facebook"></i>
            </a>
            <a
              href="https://instagram.com/ecuimport?igshid=n2l4tk1eu75s"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram"
            >
              <i className="icofont-instagram"></i>
            </a>
            {/* <a href="/#" className="skype">
              <i className="icofont-skype"></i>
            </a> */}
            <a href="https://wa.link/3xdqx8" target="_blank"  rel="noopener noreferrer" className="whatsapp">
              <i className="icofont-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>

      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto">
            <a href="/#">
              <img src={Log}></img>
            </a>
            {localStorage.getItem("token") ? (
              <a
                href="#"
                onClick={salir}
                className="salirmovil"
                style={{ display: "block", fontSize: "12px", color:"#fffn" }}
              >
                Salir
              </a>
            ) : null}
          </h1>

          <nav className="nav-menu d-none d-lg-block">
            <ul>
              {localStorage.getItem("token") &&
              localStorage.getItem("estado") !== "0" ? (
                <>
                <li className="">
                  {/* {state.rol!==null? <Link to="/pedidos">Pedidos</Link> : <Link to="/pedidos">Mis pedidos</Link>} */}
                </li>
                <li className="">
                {state.rol!==null?<Link to="/opiniones">Opiniones</Link> :  <Link to="/opiniones">Mis opiniones</Link>}

              </li>
              <li className="">
                {state.rol!==null?<Link to="/cambiarClave">Cambiar clave</Link> :  <Link to="/cambiarClave">Cambiar clave </Link>}

              </li>
              
              
              <li className="">
                  {state.rol!==null? <Link to="/usuarios">Clientes</Link>:null}
              </li>
              <li className="">
                {state.rol!==null? <Link to="/trackingsClientes">Trackings Clientes</Link>:null}
              </li>
              <li className="">
                  {state.rol!==null? <Link to="/crearPedido">Crear pedido</Link>:null}
              </li>
                
              <li className="tracking">
                  {/* {state.rol!==null? <Link to="/crearTracking">Crear tracking</Link>:<Link to="/buscarTracking">Rastrea tu paquete</Link>} */}
                  <Link to="/buscarTracking">Rastrea tu paquete</Link>
              </li>   
                               
              
              </> ):
                <>
                  <li className="active">
                    <a href="/#">Inicio</a>
                  </li>
                  <li>
                    <a href="/#about">Quienes somos</a>
                  </li>
                  <li>
                    <a href="/#services">Nuestros servicios</a>
                  </li>

                  {/*}  <li className="drop-down">
                <a href="/">Drop Down</a>
                <ul>
                  <li>
                    <a href="/#">Drop Down 1</a>
                  </li>
                  <li className="drop-down">
                    <a href="/#">Deep Drop Down</a>
                    <ul>
                      <li>
                        <a href="/#">Deep Drop Down 1</a>
                      </li>
                      <li>
                        <a href="/#">Deep Drop Down 2</a>
                      </li>
                      <li>
                        <a href="/#">Deep Drop Down 3</a>
                      </li>
                      <li>
                        <a href="/#">Deep Drop Down 4</a>
                      </li>
                      <li>
                        <a href="/#">Deep Drop Down 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/#">Drop Down 2</a>
                  </li>
                  <li>
                    <a href="/#">Drop Down 3</a>
                  </li>
                  <li>
                    <a href="/#">Drop Down 4</a>
                  </li>
                </ul>
              </li>*/}
                  <li>
                    <a href="/#testimonios">Testimonios</a>
                  </li>
                  <li className="tracking">
                    <Link to="/buscarTracking">Rastrea tu paquete</Link>
                  </li>   
                  <li>
                    <a href="/#contact">Contáctenos</a>
                  </li>
                </>}

              {!localStorage.getItem("token") ? (
                <Link
                  id="iniciar"
                  to={"/login"}
                  className="get-started-btn scrollto"
                >
                  Iniciar
                </Link>
              ) : (
                <>
                  <li className="drop-down">
                    <Link to="/">{localStorage.getItem("correo")}</Link>
                    <ul>
                      <li>
                        <p>{localStorage.getItem("nombres")}</p>
                      </li>
                      {/*<li>
                        <Link to="/#">Perfil</Link>
                     </li>*/}
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </header>

      <Suspense
        fallback={
          <center>
            <img
              src={logo}
              alt="loading..."
              style={{ marginTop: "150px", marginBottom: "100px" }}
            />
          </center>
        }
      >
        <Routes />
      </Suspense>

      <footer id="footer">
        <div className="container">
          {/* <h3>ECUIMPORT</h3> */}
          <img src={Log2}></img>
          <p>SIGUENOS EN NUESTRAS REDES SOCIALES</p>
          <div className="social-links">
            {/* <a href="/#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
            <a
              href="https://www.facebook.com/ecuimport-100979977916059"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
            >
              <i className="bx bxl-facebook"></i>
            </a>
            <a
              href="https://instagram.com/ecuimport?igshid=n2l4tk1eu75s"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram"
            >
              <i className="bx bxl-instagram"></i>
            </a>
            {/* <a href="/#" className="google-plus"><i className="bx bxl-skype"></i></a> */}
            <a href="https://wa.link/3xdqx8" target="_blank" rel="noopener noreferrer" className="whatsapp">
              <i className="bx bxl-whatsapp"></i>
            </a>
          </div>
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>
                {" "}
                <a href="https://www.grouptechnologys.com" target="_blank" rel="noopener noreferrer">
                  Group Technology
                </a>{" "}
                (Un mundo de innovacion.)
              </span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits"></div>
        </div>
      </footer>

      <a href="/#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </a>
    </>
  );
};

export default Layout;
